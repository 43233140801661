<template>
  <div class="dashboard-card">
    <div class="dashboard-card-title">
      <h3>Store</h3>
      <p>Quickly upload more files to your account</p>
    </div>
    <div class="dashboard-card-body">
      <div class="store-item">
        <div class="icon">
          <b-img
            :src="'https://image.illustrativedna.com/assets/icons/menu/'+$store.state.appConfig.layout.skin+'/'+theme+'/deep-ancestry.svg'"
            height="115"
            width="110"
          />
        </div>
        <div class="title g25-text">DEEP ANCESTRY</div>

        <div class="store-item-left">
          <div class="buttons-group">
            <!-- <button class="lab-btn lab-btn-outline-info"> Info </button> -->
            <button class="lab-btn lab-btn-success ml-1" @click="$router.push(`/checkout/da/${DA_CHECKOUT_STEPS.UPLOAD}/${PRODUCT_CODES.UPLOAD_DA_RAW_DATA}`)"> New Order </button>
          </div>
          <div class="price g25-text">
            {{g25Price}}€
          </div>
        </div>
      </div>

      <!-- <hr/>

      <div class="store-item">
        <div class="icon">
          <b-img
            :src="'https://image.illustrativedna.com/assets/icons/menu/'+$store.state.appConfig.layout.skin+'/'+theme+'/trait-health.svg'"
            height="115"
            width="110"
          />
        </div>
        <div class="title trait-health-text">TRAITS & HEALTH</div>

        <div class="store-item-left">
          <span class="lab-label-comming-soon" style="position: relative;">Coming Soon</span>
          <div class="buttons-group">
            <button class="lab-btn lab-btn-outline-info"> Info </button>
            <button class="lab-btn lab-btn-sunrise ml-1" 
              @click="$router.push(`/checkout/trait/${TRAIT_CHECKOUT_STEPS.UPLOAD}/${PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA}`)"> 
              Order Now 
            </button>
          </div>
          <div class="price trait-health-text">
            {{traitsPrice}}€
          </div>
        </div>

        <span class="lab-label-beta">Beta</span>
      </div>

      <hr/>

      <div class="store-item">
        <div class="icon">
          <b-img
            :src="'https://image.illustrativedna.com/assets/icons/menu/'+$store.state.appConfig.layout.skin+'/'+theme+'/ibd.svg'"
            height="115"
            width="110"
          />
        </div>
        <div class="title ibd-text">POPULATION-WISE IBD</div>

        <div class="store-item-left">
          <span class="lab-label-comming-soon" style="position: relative;">Coming Soon</span>
          <div class="buttons-group">
            <button class="lab-btn lab-btn-outline lab-btn-outline-info"> Info </button>
            <button class="lab-btn lab-btn-marigold ml-1" disabled> Order Now </button>
          </div>
          <div class="price ibd-text">
            00.00€
          </div>
        </div>
        
      </div> -->

    </div>
  </div>
</template>

<script>
import { PRODUCT_CODES, DA_CHECKOUT_STEPS, TRAIT_CHECKOUT_STEPS } from '@/common/config';

export default {
  components: {
  },
  data() {
    return {
      PRODUCT_CODES,
      DA_CHECKOUT_STEPS,
      TRAIT_CHECKOUT_STEPS,
    }
  },
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin === 'light' ? 'normal' : 'active';
    },
    products() {
      return this.$store.getters['products/getProducts'];
    },
    g25Price() {
      const product = this.products.find(x => x.code === PRODUCT_CODES.UPLOAD_DA_RAW_DATA);
      
      if (product) {
        return product.unitPrice;
      }

      return 26.99;
    },
    traitsPrice() {
      const product = this.products.find(x => x.code === PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA);
      
      if (product) {
        return product.unitPrice;
      }

      return 9.99;
    },
  },
  created() {

  },
}
</script>

<style scoped lang="scss">

.store-item {
  position: relative;
  margin-bottom: 15px;
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

.store-item .icon img {
  width: 50px !important;
  height: 50px !important;
}

.store-item .title {
  font-weight: 700;
  font-size: 1rem;
  margin-left: 15px;
  color: var(--lab-text-color-title-01);
}

.store-item .store-item-left {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.store-item .store-item-left .buttons-group {
  margin-right: 20px;
}

.store-item .store-item-left .price {
  font-weight: bold;
  font-size: 1rem;
  color: var(--lab-text-color-label-01);
}

</style>
