<template>
  <div class="mobile-dashboard-store mt-4">
    <div class="dashboard-card-title">
      <h3>Store</h3>
    </div>
    
    <div class="">
      <div class="store-item dashboard-card" @click="$router.push(`/checkout/da/${DA_CHECKOUT_STEPS.UPLOAD}/${PRODUCT_CODES.UPLOAD_DA_RAW_DATA}`)">
        <div class="icon">
          <b-img
            :src="'https://image.illustrativedna.com/assets/icons/menu/'+$store.state.appConfig.layout.skin+'/'+theme+'/deep-ancestry.svg'"
            height="115"
            width="110"
          />
        </div>
        <div class="title g25-text">DEEP ANCESTRY</div>

        <div class="store-item-left">
          <div class="price g25-text">
            26.99€
          </div>
        </div>
      </div>

      <!-- <div class="store-item dashboard-card" @click="$router.push(`/checkout/trait/${TRAIT_CHECKOUT_STEPS.UPLOAD}/${PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA}`)">
        <div class="icon">
          <b-img
            :src="'https://image.illustrativedna.com/assets/icons/menu/'+$store.state.appConfig.layout.skin+'/'+theme+'/trait-health.svg'"
            height="115"
            width="110"
          />
        </div>
        <div class="title trait-health-text">TRAITS & HEALTH</div>

        <div class="store-item-left">
          <div class="price trait-health-text">
            26.99€
          </div>
        </div>
        
        <span class="lab-label-beta">Beta</span>
      </div>

      <div class="store-item dashboard-card">
        <div class="icon">
          <b-img
            :src="'https://image.illustrativedna.com/assets/icons/menu/'+$store.state.appConfig.layout.skin+'/'+theme+'/ibd.svg'"
            height="115"
            width="110"
          />
        </div>
        <div>
          <div class="title ibd-text">POPULATION-WISE IBD</div>
          <span class="lab-label-comming-soon title" style="position: relative; margin-top: 5px;">Coming Soon</span>
        </div>
       
        <div class="store-item-left">
          <div class="price ibd-text">
            26.99€
          </div>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>
import { DA_CHECKOUT_STEPS, TRAIT_CHECKOUT_STEPS, PRODUCT_CODES } from '@/common/config';

export default {
  components: {
  },
  data() {
    return {
      PRODUCT_CODES,
      DA_CHECKOUT_STEPS,
      TRAIT_CHECKOUT_STEPS,
    }
  },
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin === 'light' ? 'normal' : 'active';
    },
  },
  created() {

  },
}
</script>

<style scoped lang="scss">
.mobile-dashboard-store {
  position: relative;
}
.mobile-dashboard-store h3 {
  color: var(--lab-black);
  font-weight: 600;
}

.store-item {
  position: relative;
  margin-bottom: 15px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

.store-item .icon img {
  width: 50px !important;
  height: 50px !important;
}

.store-item .title {
  font-weight: 700;
  font-size: 1rem;
  margin-left: 15px;
  color: var(--lab-text-color-title-01);
}

.store-item .store-item-left {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.store-item .store-item-left .buttons-group {
  margin-right: 20px;
}

.store-item .store-item-left .price {
  font-weight: bold;
  font-size: 1rem;
}

.dark-layout {
  .mobile-dashboard-store h3 {
    color: var(--lab-lago-blue-color);
  }
}
</style>
