<template>
  <div class="main-body  mx-auto">
    <div v-if="hasMissedFile">
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span class="mr-1 mt-1"><strong>Warning!</strong> Incorrect or missing raw-data file detected. Please upload missing files.</span>
          <button class="lab-btn lab-btn-info" @click="openOrderUpload">Upload Missing Files</button>
        </div>
      </b-alert>

      <UploadOrderMissedFilesModal v-if="uploadModalObject.showModal" :modalObject="uploadModalObject"/>
    </div>

    <div v-if="hasV1OrderResult">
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span class="mr-1 mt-1"><strong>Important!</strong> To receive the latest updates, please upgrade your current kits to v2.</span>
          <button class="lab-btn lab-btn-info" @click="$router.push({ name: 'dashboard-upgradev2'})">Upgrade to v.2</button>
        </div>
      </b-alert>

      <UploadOrderMissedFilesModal v-if="uploadModalObject.showModal" :modalObject="uploadModalObject"/>
    </div>

    <div v-if="pendingOrder">
      <b-alert
        variant="info"
        show
      >
        <div class="alert-body">
          <span><strong>note!</strong> Your results are being processed and will be ready by tomorrow. We appreciate your patience.</span>
        </div>
      </b-alert>
      
    </div>
    <div v-if="completedOrder && showViewResultAlert">
      <b-alert dismissible @dismissed="handleAlertDismiss" variant="info" show>
        <div class="alert-body">
          Click on DEEP ANCESTRY to view your results.
        </div>
      </b-alert>
    </div>
    
    <div class="dashboard">
      <b-row>
        <b-col class="mb-2" cols="12" xl="6" md="12">
          <dashboard-kits></dashboard-kits>
        </b-col>
        <b-col class="mb-2" cols="12" xl="6" md="12">
          <dashboard-order></dashboard-order>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xl="7" md="7">
          <dashboard-store></dashboard-store>
        </b-col>
        <b-col cols="12" xl="5" md="5">
          <dashboard-tickets></dashboard-tickets>
        </b-col>
      </b-row>
    </div>
    <div class="dashboard-mobile">
      <mobile-dashboard-services />
      <mobile-dashboard-others  class="mt-4"/>
      <mobile-dashboard-store />
    </div>
  </div>
</template>

<script>
import DashboardTickets from './dashboards/main-dashboard/DashboardTickets.vue'
import DashboardStore from './dashboards/main-dashboard/DashboardStore.vue'
import DashboardOrder from './dashboards/main-dashboard/DashboardOrder.vue'
import DashboardKits from './dashboards/main-dashboard/DashboardKits.vue'
import MobileDashboardServices from './dashboards/main-dashboard/mobile/MobileDashboardServices.vue'
import MobileDashboardStore from './dashboards/main-dashboard/mobile/MobileDashboardStore.vue'
import MobileDashboardOthers from './dashboards/main-dashboard/mobile/MobileDashboardOthers.vue'
import UploadOrderMissedFilesModal from './dashboards/main-dashboard/upload-file/UploadOrderMissedFilesModal.vue'

export default {
  components: {
    DashboardKits,
    DashboardTickets,
    DashboardStore,
    DashboardOrder,
    MobileDashboardServices,
    MobileDashboardStore,
    MobileDashboardOthers,
    UploadOrderMissedFilesModal,
  },
  data() {
    return {
      hasMissedFile: false,
      showViewResultAlert: true,
      uploadModalObject: {
        showModal: false,
        onSuccess: this.checkMissedFile,
      },
    }
  },
  methods: {
    loadProducts() {
      this.$store.dispatch('products/fetchProducts', {});
    },
    checkMissedFile() {
      this.$store.dispatch('orders/hasMissedFileFetch', {})
        .then((response) => {
          if (response.statusCode === 200) {
            this.hasMissedFile = response.result;
          }
        })
    },
    openOrderUpload() {
      this.uploadModalObject.showModal = true;
    },
    handleAlertDismiss() {
      localStorage.setItem("show_View_result_alert_dismissed", "true");
      this.showViewResultAlert = false;
    },
  },
  computed: {
    pendingOrder() {
      return this.$store.getters['orders/getOrders'].find(x => x.orderStatus === 1);
    },
    completedOrder() {
      return this.$store.getters['orders/getOrders'].find(x => x.orderStatus === 3);
    },

    hasV1OrderResult() {
      return this.$store.getters['orderResult/getOrderResults'].find(x => x.dataVersion === 'D-01');
    },
  },
  created() {
    this.checkMissedFile();
    this.loadProducts();
  },
  mounted() {
    if (localStorage.getItem("show_View_result_alert_dismissed")) {
      this.showViewResultAlert = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.main-body {
  max-width: 1300px !important;
}

.title-header-text {
  color: #898989 !important;
}

@media screen and (max-width: 769px) {
  .dashboard {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .dashboard-mobile {
    display: none !important;
  }
}
</style>
